import React from "react"

import styles from "./Input.module.css"

const InputView = ({
  value = undefined,
  type = "text",
  placeholder,
  handleChange,
}) => {
  return (
    <input
      className={styles.input}
      type={type}
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
    />
  )
}

export default InputView
