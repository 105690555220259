import React from "react"
import ImageSection from "../ImageSection"

import UnsubscribeForm from "./UnsubscribeForm"
import styles from "./Unsubscribe.module.css"

const UnsubscribeView = () => {
  return (
    <div>
      <ImageSection />
      <section className={styles.section}>
        <h1>UNSUBSCRIBE</h1>
        <div className={styles.formCont}>
          <UnsubscribeForm />
        </div>
      </section>
    </div>
  )
}

export default UnsubscribeView
