import React from "react"

import Button from "../../UI/Button"
import Input from "../../UI/Input"

import styles from "./UnsubscribeForm.module.css"

const UnsubscribeFormView = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapp}>
        <p>
          If you would like to Unsubscribe to communications from
          handicaploan.com and our affiliates, enter your email address in the
          box below. Our affiliates will be notified of your desire to
          Unsubscribe and must comply with your request.
        </p>
      </div>
      <div className={styles.inputWrapp}>
        <Input placeholder="To Unsubscribe, Enter Your Email Address Here" />
      </div>
      <Button>GET STARTED</Button>
    </div>
  )
}

export default UnsubscribeFormView
